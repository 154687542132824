import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import { jsx, css } from "@emotion/core"

export default function Layout({ children }) {
  return (
    <div css={siteContent}>
      <Navbar />
      <div css={page}>{children}</div>
      <Footer />
    </div>
  )
}

const siteContent = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const page = css`
  flex: 1 0 auto;
`
