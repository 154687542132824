import React from "react"
import { jsx, css } from "@emotion/core"

export default function Button(props) {
  return <button css={buttonStyle}>{props.content}</button>
}

Button.defaultProps = {
  content: "submit",
}
const buttonStyle = css`
  background: #feb836;
  text-shadow: none;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid transparent;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  color: #fff;
  background-color: #feb836;
  border-color: #feb836;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  & :hover {
    background-color: #e8a01a;
    border-color: #e8a01a;
  }
`
