import React, { useState } from "react"
import { jsx, css } from "@emotion/core"
import Button from "../general/button/button"

const Navbar = () => {
  const [burgerMenu, toogleBurgerMenu] = useState(false)
  return (
    <nav
      css={css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        align-items: center;
        background-color: #212529;
        position: sticky;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
      `}
    >
      <div css={NavbarStyle}>
        {/* logo */}
        <a href="/" css={Logo}>
          VAIDOTO 183
        </a>
        {/* navigation links */}

        <div
          onClick={() => {
            toogleBurgerMenu(!burgerMenu)
          }}
          css={MobileDropdown}
        >
          <Button content={"Menu"} css={MenuButton} />
        </div>

        <div css={NavigationPanel}>
          {burgerMenu && (
            <ul css={MobileLinkList}>
              <li>
                <a href="/autoserviso-paslaugos" css={NavbarLink}>
                  Paslaugos
                </a>
                <ul className="dropdown-content">
                  <li>
                    <a href="/padangu-montavimas" css={NavbarLinkDropdown}>
                      Padangų montavimas ir balansavimas
                    </a>
                  </li>
                  <li>
                    <a href="/tepalu-keitimas" css={NavbarLinkDropdown}>
                      Tepalų keitimas
                    </a>
                  </li>
                  <li>
                    <a href="/vaziuokles-remontas" css={NavbarLinkDropdown}>
                      Važiuoklės remontas
                    </a>
                  </li>
                  <li>
                    <a href="/automobilio-diagnostika" css={NavbarLinkDropdown}>
                      Diagnostika
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a css={NavbarLink}>Blogas</a>
              </li> */}
              <li>
                <a css={NavbarLink} href="/apie-mus">
                  Kodėl rinktis mus
                </a>
              </li>
              <li>
                <a css={NavbarLink} href="/duk">
                  D.U.K.
                </a>
              </li>
              <li>
                <a css={NavbarLink} href="/kontaktai">
                  Kontaktai
                </a>
              </li>
            </ul>
          )}
          <ul css={DesktopLinkList}>
            <li css={Dropdown}>
              <a href="/autoserviso-paslaugos" css={NavbarLink}>
                Paslaugos<span css={caret}></span>
              </a>
              <ul css={DropdownContent} className="dropdown-content">
                <li>
                  <a href="/padangu-montavimas" css={NavbarLinkDropdown}>
                    Padangų montavimas ir balansavimas
                  </a>
                </li>
                <li>
                  <a href="/tepalu-keitimas" css={NavbarLinkDropdown}>
                    Tepalų keitimas
                  </a>
                </li>
                <li>
                  <a href="/vaziuokles-remontas" css={NavbarLinkDropdown}>
                    Važiuoklės remontas
                  </a>
                </li>
                <li>
                  <a href="/variklio-diagnostika" css={NavbarLinkDropdown}>
                    Diagnostika
                  </a>
                </li>
              </ul>
            </li>
            {/* <li>
              <a css={NavbarLink}>Blogas</a>
            </li> */}
            <li>
              <a css={NavbarLink} href="/apie-mus">
                Kodėl rinktis mus
              </a>
            </li>
            <li>
              <a css={NavbarLink} href="/duk">
                D.U.K.
              </a>
            </li>
            <li>
              <a css={NavbarLink} href="/kontaktai">
                Kontaktai
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

// Need a workaround to target class because cannot target other css prop
const Dropdown = css`
  :hover .dropdown-content {
    display: block;
  }
`

const DropdownContent = css`
  list-style: none;
  position: absolute;
  display: none;
  padding: 0;
  background-color: #212529e3;
`

const caret = css`
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
`

const NavbarStyle = css`
  transition: padding-top 0.3s, padding-bottom 0.3s;
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 638px) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
`

const MobileDropdown = css`
  @media (min-width: 638px) {
    display: none;
  }
`

const NavigationPanel = css`
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  @media (min-width: 638px) {
    flex-basis: auto;
  }
`
const MenuButton = css`
  padding: 1.1em 1em;
`
const MobileLinkList = css`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;

  @media (min-width: 638px) {
    display: none;
  }
`

const DesktopLinkList = css`
  display: none;
  @media (min-width: 638px) {
    display: flex;
    list-style: none;
    flex-direction: row;
    margin-left: auto;
  }
`

const NavbarLinkDropdown = css`
  font-size: 16px;
  font-weight: 400;
  padding: 1.1em 0em;
  letter-spacing: 1px;
  color: #fff;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";

  &:hover {
    color: #feb836;
  }

  @media (min-width: 638px) {
    padding: 14px 16px;
  }
`

const NavbarLink = css`
  font-size: 16px;
  font-weight: 400;
  padding: 1.1em 0em;
  letter-spacing: 1px;
  color: #fff;
  display: block;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  text-transform: uppercase;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";

  &:hover {
    color: #feb836;
  }

  @media (min-width: 638px) {
    padding: 7px 15px;
  }
`

const Logo = css`
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap;
  color: #fff;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  &:hover {
    color: #feb836;
  }
`

export default Navbar
