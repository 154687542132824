import React, { useState } from "react"
import { jsx, css } from "@emotion/core"

const Footer = () => {
  return (
    <footer css={footerSection}>
      <span css={footerText}>Vaidoto g. 183 (Panemunė), 45392 Kaunas</span>
    </footer>
  )
}

const footerSection = css`
flex-shrink: 0;
text-align: center;
  margin: 0 auto;
  max-width: 1200px;
  padding 25px 15px;
`

const footerText = css`
  color: #212529;
  font-size: 15px;
  line-height: 40px;
  text-transform: none;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

export default Footer
